import React, { useEffect, useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import AlertNotificationComp from "components/custom/AlertNotificationComp";

import { gsfacilitytypes } from "lookuptables/global";

// import PipelineUpdateComp from './PipelineUpdateComp';
// import PipelineSectionUpdateComp from './PipelineSectionUpdateComp';

//linq
import linq from "linq";

//Redux imports
import { useSelector } from "react-redux";

//Api
import axios from "axios";

//Redux imports
import { useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import { withRouter } from "react-router-dom";
import GatheringSystemUpdateComp from "./GatheringSystemUpdateComp";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Form, Table } from "antd";

const useStyles = makeStyles((theme) => ({
  operatordatacontainer: {
    margin: 10,
    width: "100%",
    "& .MuiPaper-root": {
      width: "100%",
    },
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      marginBottom: 3.5,
    },
  },

  operatorlist: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    maxHeight: 700,
    minHeight: 700,
  },

  updatebutton: {
    width: "200px",
  },
  tabsstyle: {
    "& .MuiTab-root": {
      textTransform: "none",
    },
  },
}));

function GatheringSystemConnsComp(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const [pipelines, setPipelines] = React.useState([]);
  const [pipelinefacilities, setPipelineFacilities] = React.useState([]);
  const [selectedpipelineheader, setSelectedPipelineHeader] = React.useState(
    {}
  );
  const [selectedpipelinefacility, setSelectedPipelineFacility] =
    React.useState({});

  const [gsconns, setGSConns] = React.useState([]);
  const [gsid, setGSId] = React.useState(0);

  const gscolumns = [
    {
      title: "FacilityId",
      dataIndex: "FacilityId",
    },
    {
      title: "FacilityName",
      dataIndex: "FacilityName",
    },
    {
      title: "FacilityType",
      dataIndex: "FacilityType",
    },
  ];

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "info" });
  };

  const fnFetchGSConns = (id) => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      gsid: id,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchgatheringsystempipeineconns", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while fetching records",
              variant: "error",
            });
          } else if (response.data[0].status == "no records found") {
            setStatusBase({
              show: true,
              message: "Gathering System data not found",
              variant: "info",
            });
          } else if (response.data[0].data.length > 0) {
            setGSConns(response.data[0].data);
          }
        } else {
          setStatusBase({
            show: true,
            message:
              "Error while fetching gathering system data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching gathering system data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnOnChange = (newvalue, type, typeid) => {
    //Fetch Pipeline Facilities
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      pipelineid: newvalue.PipelineHeaderId,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchpipelinefacilitiesbyid", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while fetching records",
              variant: "error",
            });
          } else if (response.data[0].status == "no records found") {
            setStatusBase({
              show: true,
              message: "Gathering System data not found",
              variant: "info",
            });
          } else if (response.data[0].data.length > 0) {
            setPipelineFacilities(response.data[0].data);
          }
        } else {
          setStatusBase({
            show: true,
            message:
              "Error while fetching gathering system data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching gathering system data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnAddGSConn = () => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      gsid: gsid,
      facilityid: selectedpipelinefacility.FacilityId,
      facilitytype: selectedfacilitytype,
    };

debugger;

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/insertgspipelineconn", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });
        // console.log(response.data[0]);
        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: `Error while inserting gathering system connection,${response.data[0].msg}`,
            variant: "error",
          });
        } else if (response.data[0].status === "inserted") {
          setStatusBase({
            show: true,
            message: "Connection Inserted",
            variant: "success",
          });

          setSelectedPipelineFacility({});
          setSelectedPipelineHeader({});
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        setStatusBase({
          show: true,
          message: "Error while inserting gathering system connection",
          variant: "error",
        });
      }
    );
  };

  const fnFetchLookupValues = () => {
    const data = {
      fetchtype: "PipelineName",
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchuniquevalues", data, options).then(
      (response) => {
        // console.log(response.data[0]);
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            var data = [];
            response.data[0].data.forEach((element) => {
              data.push({
                PipelineName: element["PipelineName"],
                PipelineHeaderId: element["PipelineHeaderId"],
              });
            });

            setPipelines(data);
          } else {
          }
        }
      },
      (error) => {}
    );
  };

  const [selectedfacilitytype, setSelectedFacilityType] = React.useState("");
  const [selectedfacility, setSelectedFacility] = React.useState({});
  const [facilities, setFacilities] = React.useState([]);
  const fnFetchFacilities = (facilitytype) => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const query = { facilitytype: facilitytype };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchfacilitiesbyfacilitytype", query, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "no record found") {
          setStatusBase({
            show: true,
            message: "Facilities Not Found",
            variant: "info",
          });
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            setFacilities(response.data[0].data);
          }
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error occured while fetching facilities",
            variant: "error",
          });
        }
      }
    );
  };

  useEffect(() => {
    fnFetchLookupValues();

    if (props.id > 0) {
      setGSId(props.id);
      fnFetchGSConns(props.id);
    }
  }, []);

  return (
    <>
      <Grid className={classes.operatordatacontainer} container>
        <Grid
          container
          spacing="1"
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          {/* <Grid item xs="4">
            <Autocomplete
              size="small"
              options={pipelines}
              getOptionLabel={(option) =>
                option.PipelineName === undefined ? "" : option.PipelineName
              }
              value={selectedpipelineheader}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setSelectedPipelineHeader(newValue);
                  fnOnChange(newValue, "PipelineName", "PipelineHeaderId");
                } else {
                  setSelectedPipelineHeader({});
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={"Select Pipeline"}
                  placeholder="Pipeline"
                  error={selectedpipelineheader.PipelineName === undefined}
                />
              )}
            />
          </Grid> */}
          <Grid item xs="4">
            <Autocomplete
              size="small"
              options={gsfacilitytypes}
              getOptionLabel={(option) => option}
              value={selectedfacilitytype}
              onChange={(event, newValue) => {
                setSelectedFacility({});
                if (newValue !== null) {
                  setSelectedFacilityType(newValue);
                  fnFetchFacilities(newValue);
                } else {
                  setSelectedFacilityType("");
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={"Select Facility Type"}
                  placeholder="Select Facility Type"
                  error={selectedfacilitytype === ""}
                />
              )}
            />
          </Grid>

          <Grid item xs="5">
            <>
              {/* {pipelinefacilities.length > 0 && (
                <Autocomplete
                  size="small"
                  options={pipelinefacilities}
                  getOptionLabel={(option) =>
                    option.Terminal === undefined
                      ? ""
                      : option.Terminal + " - " + option.TerminalType
                  }
                  value={selectedpipelinefacility}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setSelectedPipelineFacility(newValue);
                    } else {
                      setSelectedPipelineFacility({});
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={"Select Facility"}
                      placeholder="Facility"
                    />
                  )}
                />
              )} */}
              {/* {JSON.stringify(selectedpipelinefacility)} */}
              {facilities.length > 0 && (
                <Autocomplete
                  size="small"
                  options={facilities}
                  getOptionLabel={(option) => option.FacilityName}
                  value={selectedpipelinefacility}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setSelectedPipelineFacility(newValue);
                    } else {
                      setSelectedPipelineFacility({});
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={"Select Facility"}
                      placeholder="Select Facility"
                    />
                  )}
                />
              )}
            </>
          </Grid>
          <Grid item xs="1">
            <Button
              type="button"
              color="primary"
              variant="contained"
              className={classes.updatebutton}
              onClick={() => fnAddGSConn()}
            >
              Add
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Paper>
            <Form component={false}>
              <Table
                columns={gscolumns}
                dataSource={gsconns}
                className={classes.editabletable}
                bordered
                title={() => {
                  return `${gsconns.length} Records Found`;
                }}
                size="middle"
                scroll={{ x: 1000, y: 500 }}
              />
            </Form>
          </Paper>
        </Grid>
      </Grid>

      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(GatheringSystemConnsComp);
