import React, { Suspense, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import shpwrite from "shp-write";
import filedownload from "js-file-download";
import FileSaver from "file-saver";

import AlertNotificationComp from "components/custom/AlertNotificationComp";
import DDGroupButtonComp from "components/common/DDGroupButtonComp";
import SearchPLCFacilityComp from "components/custom/SearchPLCFacilityComp";

import { withRouter } from "react-router-dom";

//Redux imports
import { useSelector, useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

//Api
import axios from "axios";

const OperatorInfoComponent = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./subcomponents/OperatorInfoComp")), 100)
    )
);

const GasplantInfoComponent = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./subcomponents/GasPlantInfoComp")), 100)
    )
);



const GasStorageInfoComponent = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./subcomponents/GasStorageInfoComp")),
        100
      )
    )
);

const RefineryInfoComponent = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./subcomponents/RefineryInfoComp")), 100)
    )
);

const WellInfoComponent = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./subcomponents/WellInfoComp")), 100)
    )
);

const TerminalInfoComponent = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./subcomponents/TerminalInfoComp")), 100)
    )
);

const PipelineInfoComponent = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./subcomponents/PipelineInfoComp")), 100)
    )
);

const LiquefactionInfoComponent = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./subcomponents/LiquefactionInfoComp")),
        100
      )
    )
);

const PipelineAssemblyInfoComponent = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./subcomponents/PipelineAssemblyInfoComp")),
        100
      )
    )
);

const GatheringSystemInfoComponent = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./subcomponents/GatheringSystemInfoComp")),
        100
      )
    )
);

const CompressorStationInfoComponent = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./subcomponents/CompressorStationInfoComp")),
        100
      )
    )
);

const PumpingStationInfoComponent = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./subcomponents/PumpingStationInfoComp")),
        100
      )
    )
);

const IndustrialPlantInfoComponent = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./subcomponents/IndustrialPlantInfoComp")),
        100
      )
    )
);

const PowerPlantInfoComponent = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./subcomponents/PowerPlantInfoComp")),
        100
      )
    )
);

const TariffInfoComponent = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./subcomponents/TariffInfoComp")), 100)
    )
);

const HydrogenPlantInfoComponent = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./subcomponents/HydrogenPlantInfoComp")),
        100
      )
    )
);

const CCUSInfoComponent = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./subcomponents/CCUSInfoComp")), 100)
    )
);

const CO2InfoComponent = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./subcomponents/CO2InfoComp")), 100)
    )
);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  card: {
    marginLeft: 20,
    marginTop: 20,
  },
}));

function HomeComp(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);
  const operatorscount = useSelector(
    (state) => state.facilitiescountReducer.Operators
  );

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
  });

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "" });
  };

  const [facilitiesdata, setFacilitiesData] = React.useState([]);

  const [slcdialog, setSlcDialog] = React.useState(false);

  const fnFetchLookupValues = async () => {
    const lookups = [
      { name: "OperatorName", id: "OperatorId" },
      { name: "CityName", id: "CityId" },
      { name: "CountyName", id: "CountyId", stateid: "StateNameId" },
      { name: "StateName", id: "StateNameId" },
      { name: "Zipcode", id: "ZipcodeId" },
      { name: "OperatorType", id: "OperatorTypeId" },
      { name: "BasinName", id: "BasinId" },
      { name: "RefineryProduct", id: "RefineryProductId" },
      { name: "CommodityHandled", id: "CommodityHandledId" },
    ];

    const actions = {
      OperatorName: "ADD_OPERATORS",
      CityName: "ADD_CITYNAMES",
      CountyName: "ADD_COUNTYNAMES",
      StateName: "ADD_STATENAMES",
      OperatorType: "ADD_OPERATORTYPES",
      Zipcode: "ADD_ZIPCODES",
      BasinName: "ADD_BASINS",
      RefineryProduct: "ADD_REFINERYPRODUCT",
      CommodityHandled: "ADD_COMMODITYHANDLED",
    };

    let promises = [];

    lookups.forEach((table) => {
      const data = {
        fetchtype: table.name,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : "",
        },
      };

      axios.post("/api/fetchuniquevalues", data, options).then(
        (response) => {
          // console.log(response.data[0]);
          if (response.data[0].status === "error") {
          } else if (response.data[0].status === "success") {
            if (response.data[0].data.length > 0) {
              var data = [];

              if (table.name === "CountyName") {
                response.data[0].data.forEach((element) => {
                  data.push({
                    [table.name]: element[table.name],
                    [table.id]: element[table.id],
                    [table.stateid]: element[table.stateid],
                  });
                });
              } else {
                response.data[0].data.forEach((element) => {
                  data.push({
                    [table.name]: element[table.name],
                    [table.id]: element[table.id],
                  });
                });
              }

              // console.log(data.length);

              // if (table.name === 'OperatorName') {
              // 	dispatch({
              // 		type: actionType.ADD_OPERATORS,
              // 	});
              // }

              // console.log([table.name]);

              dispatch({
                type: actions[table.name],
                payload: {
                  [table.name]: data,
                },
              });
            } else {
            }
          }
        },
        (error) => {}
      );
    });
  };

  const fnFetchParallelLookupValues = () => {
    const lookups = [
      { name: "OperatorName", id: "OperatorId" },
      { name: "CityName", id: "CityId" },
      { name: "CountyName", id: "CountyId" },
      { name: "StateName", id: "StateNameId" },
      { name: "Zipcode", id: "ZipcodeId" },
      { name: "OperatorType", id: "OperatorTypeId" },
      { name: "BasinName", id: "BasinId" },
    ];

    const actions = {
      OperatorName: "ADD_OPERATORS",
      CityName: "ADD_CITYNAMES",
      CountyName: "ADD_COUNTYNAMES",
      StateName: "ADD_STATENAMES",
      OperatorType: "ADD_OPERATORTYPES",
      Zipcode: "ADD_ZIPCODES",
      BasinName: "ADD_BASINS",
    };

    let promises = [];

    promises.push(
      lookups.forEach((table) => {
        const data = {
          fetchtype: table.name,
        };

        const options = {
          headers: {
            authorization: token ? `Bearer ${token}` : "",
          },
        };

        axios.post("/api/fetchuniquevalues", data, options).then(
          (response) => {
            // console.log(response.data[0]);
            if (response.data[0].status === "error") {
            } else if (response.data[0].status === "success") {
              if (response.data[0].data.length > 0) {
                var data = [];
                response.data[0].data.forEach((element) => {
                  data.push({
                    [table.name]: element[table.name],
                    [table.id]: element[table.id],
                  });
                });

                dispatch({
                  type: actions[table.name],
                  payload: {
                    [table.name]: data,
                  },
                });
              } else {
              }
            }
          },
          (error) => {}
        );
      })
    );

    Promise.all(promises).then(() => console.log("all done"));
  };

  const fnGotoSearchFacility = () => {
    props.history.push("/searchfacility");
  };

  const fnGotoDataViewFacility = () => {
    props.history.push("/dataview");
  };

  const fnGotoPipelineGasFlowLocCodes = () => {
    props.history.push("/pipelinegasflowloccodes");
  };

  const fnGenerateFacilitiesFile = () => {
    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchallfacilities", [], options).then(
      (response) => {
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "no records") {
        } else if (response.data[0].status === "success") {
          setFacilitiesData(response.data[0].data);

          // var options = {
          // 	folder: 'myshapes',
          // 	types: {
          // 		point: 'mypoints',
          // 		polygon: 'mypolygons',
          // 		line: 'mylines',
          // 	},
          // };

          //

          // var bufferdata = shpwrite.zip(response.data[0].data);

          // const blob = new Blob([bufferdata], {
          // 	type: 'blob',
          // });

          // const filename = 'download.zip';
          // FileSaver.saveAs(bufferdata, filename);

          // shpwrite.download(response.data[0].data, options);

          // var bufferdata = shpwrite.zip(response.data[0].data, options);

          // filedownload(bufferdata, 'export.zip');

          // FileSaver.saveAs(bufferdata, 'export.zip');

          // shpwrite.zip(response.data[0].data, options).then(function (content) {
          // 	FileSaver.saveAs(content, 'export.zip');
          // });
        }
      },
      (error) => {
        if (error.request.status === 500) {
        } else {
        }
      }
    );
  };

  const fnDownloadShapeFile = () => {
    var options = {
      folder: "myshapes",
      types: {
        point: "mypoints",
        polygon: "mypolygons",
        line: "mylines",
      },
    };

    var features = [];
    facilitiesdata.forEach((storage) => {
      var feature = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [storage.Longitude, storage.Latitude],
        },
        properties: {
          FacilityName: storage.FacilityName,
          Facilitytype: storage.Facilitytype,
          Latitude: storage.Latitude,
          Longitude: storage.Longitude,
          StateName: storage.statename,
          CountyName: storage.CountyName,
          BasinName: storage.BasinName,
          Zipcode: storage.Zipcode,
        },
      };

      features.push(feature);
    });
    // a GeoJSON bridge for features
    shpwrite.download(
      {
        type: "Facilities",
        features: features,
      },
      options
    );
  };

  const fnFetchFacilitiesCount = () => {
    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchallfacilitiescount", [], options).then(
      (response) => {
        // console.log(response.data[0]);
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            dispatch({
              type: actionType.FACILITIES_COUNT,
              payload: {
                GasStorage: response.data[0].data[0].GasStorage,
                GasPlant: response.data[0].data[0].GasPlant,
                GasPlantUnit:response.data[0].data[0].GasPlantUnit,
                Terminal: response.data[0].data[0].Terminal,
                Refinery: response.data[0].data[0].Refinery,
                Liquefaction: response.data[0].data[0].Liquefaction,
                CompressorStation: response.data[0].data[0].CompressorStation,
                PumpingStation: response.data[0].data[0].PumpingStation,
                Operators: response.data[0].data[0].Operators,
                Pipeline: response.data[0].data[0].Pipeline,
                Well: response.data[0].data[0].Well,
                HydrogenPlant: response.data[0].data[0].HydrogenPlant,
                HydrogenData: response.data[0].data[0].HydrogenData,
                CCUS: response.data[0].data[0].CCUS,
                CCUSData: response.data[0].data[0].CCUSData,
                CO2: response.data[0].data[0].CO2,
                CO2StorageData: response.data[0].data[0].CO2StorageData,
                GatheringSystem: response.data[0].data[0].GatheringSystem,
                IndustrialPlant: response.data[0].data[0].IndustrialPlant,
                PowerPlant: response.data[0].data[0].PowerPlant,

              },
            });
          } else {
          }
        }
      },
      (error) => {}
    );
  };

  const fnDownloadGeoJSON = (type, filetype) => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const queryDetails = {
      subject: filetype,
      downloadtype: type,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/downloadfacilitiesgeojson", queryDetails, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (
          response.data[0] !== undefined &&
          response.data[0].status === "error"
        ) {
          setStatusBase({ show: true, message: "", variant: "error" });
        } else if (
          response.data[0] !== undefined &&
          response.data[0].status === "no records found"
        ) {
          setStatusBase({
            show: true,
            message: "No records found",
            variant: "info",
          });
        } else {
          filedownload(JSON.stringify(response.data), filetype + ".json");
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });
        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
          setStatusBase({ show: true, message: "", variant: "error" });
        }
      }
    );
  };

  const fnOpenLCDialog = () => {
    setSlcDialog(true);
  };

  const fncloseslcdialog = () => {
    setSlcDialog(false);
  };

  useEffect(() => {
    if (operatorscount === 0) {
      fnFetchFacilitiesCount();
    }
    fnFetchLookupValues();
    //fnFetchParallelLookupValues();
  }, []);

  useEffect(() => {
    if (facilitiesdata.length > 0) {
      // fnDownloadShapeFile();
    }
  }, [facilitiesdata]);

  useEffect(() => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });
  }, []);

  return (
    <>
      <Grid
        container
        className={classes.root}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        {/* <Grid item className={classes.card} xs={2}>
					<Suspense
						fallback={
							<>
								<LinearProgress />
							</>
						}
					>
						<OperatorInfoComponent />
					</Suspense>
				</Grid> */}
        <Grid item className={classes.card} xs={2}>
          <Suspense
            fallback={
              <>
                <LinearProgress />
              </>
            }
          >
            <GasplantInfoComponent />
          </Suspense>
        </Grid>
        <Grid item className={classes.card} xs={2}>
          <Suspense
            fallback={
              <>
                <LinearProgress />
              </>
            }
          >
            <GasStorageInfoComponent />
          </Suspense>
        </Grid>
        <Grid item className={classes.card} xs={2}>
          <Suspense
            fallback={
              <>
                <LinearProgress />
              </>
            }
          >
            {/* <GasPlantUnitInfoComponent />
          </Suspense>
        </Grid>
        <Grid item className={classes.card} xs={2}>
          <Suspense
            fallback={
              <>
                <LinearProgress />
              </>
            } */}
          
            <RefineryInfoComponent />
          </Suspense>
        </Grid>
        <Grid item className={classes.card} xs={2}>
          <Suspense
            fallback={
              <>
                <LinearProgress />
              </>
            }
          >
            <TerminalInfoComponent />
          </Suspense>
        </Grid>
        <Grid item className={classes.card} xs={2}>
          <Suspense
            fallback={
              <>
                <LinearProgress />
              </>
            }
          >
            <LiquefactionInfoComponent />
          </Suspense>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.root}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item className={classes.card} xs={2}>
          <Suspense
            fallback={
              <>
                <LinearProgress />
              </>
            }
          >
            <OperatorInfoComponent />
          </Suspense>
        </Grid>
        <Grid item className={classes.card} xs={2}>
          <Suspense
            fallback={
              <>
                <LinearProgress />
              </>
            }
          >
            <CompressorStationInfoComponent />
          </Suspense>
        </Grid>
        <Grid item className={classes.card} xs={2}>
          <Suspense
            fallback={
              <>
                <LinearProgress />
              </>
            }
          >
            <PumpingStationInfoComponent />
          </Suspense>
        </Grid>
        <Grid item className={classes.card} xs={2}>
          <Suspense
            fallback={
              <>
                <LinearProgress />
              </>
            }
          >
            <PipelineInfoComponent />
          </Suspense>
        </Grid>
        
        {/* <Grid item className={classes.card} xs={2}>
          <Suspense
            fallback={
              <>
                <LinearProgress />
              </>
            }
          >
            <WellInfoComponent />
          </Suspense>
        </Grid> */}
        <Grid item className={classes.card} xs={2}>
          <Suspense
            fallback={
              <>
                <LinearProgress />
              </>
            }
          >
            <GatheringSystemInfoComponent />
          </Suspense>
        </Grid>
        <Grid item className={classes.card} xs={2}>
          <Suspense
            fallback={
              <>
                <LinearProgress />
              </>
            }
          >
            <PipelineAssemblyInfoComponent />
          </Suspense>
        </Grid>
        
        <Grid item className={classes.card} xs={2}>
          <Suspense
            fallback={
              <>
                <LinearProgress />
              </>
            }
          >
            <IndustrialPlantInfoComponent />
          </Suspense>
        </Grid>
        <Grid item className={classes.card} xs={2}>
          <Suspense
            fallback={
              <>
                <LinearProgress />
              </>
            }
          >
            <PowerPlantInfoComponent />
          </Suspense>
        </Grid>
        <Grid item className={classes.card} xs={2}>
          <Suspense
            fallback={
              <>
                <LinearProgress />
              </>
            }
          >
            <TariffInfoComponent />
          </Suspense>
        </Grid>
        <Grid item className={classes.card} xs={2}>
          <Card variant="outlined">
            <CardContent className={classes.cardcontent}>
              <Typography
                variant="h6"
                color="textSecondary"
                style={{ textAlign: "center" }}
                gutterBottom
              >
                Search Facility by Location Code
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={() => fnOpenLCDialog()}
                variant="contained"
                size="small"
                style={{ width: "100%" }}
                color="primary"
              >
                Search
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.root}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item className={classes.card} xs={2}>
          <Suspense
            fallback={
              <>
                <LinearProgress />
              </>
            }
          >
            <HydrogenPlantInfoComponent />
          </Suspense>
        </Grid>
        <Grid item className={classes.card} xs={2}>
          <Suspense
            fallback={
              <>
                <LinearProgress />
              </>
            }
          >
            <CO2InfoComponent />
          </Suspense>
        </Grid>
        <Grid item className={classes.card} xs={2}>
          <Suspense
            fallback={
              <>
                <LinearProgress />
              </>
            }
          >
            <CCUSInfoComponent />
          </Suspense>
        </Grid>

        <Grid item className={classes.card} xs={2}>
        <Card variant="outlined" >
            <CardContent className={classes.cardcontent}>
              <Typography
                variant="h6"
                color="textSecondary"
                style={{ textAlign: "center" }}
                gutterBottom
              >
                Pipeline Gas Flow Location Codes
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={() => fnGotoPipelineGasFlowLocCodes()}
                variant="contained"
                size="small"
                style={{ width: "100%" }}
                color="primary"
              >
                Update Location Codes
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item className={classes.card} xs={2}></Grid>
      </Grid>
      <Grid
        container
        className={classes.root}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item className={classes.card} xs={2}>
          <Card variant="outlined" className={classes.card}>
            <CardContent className={classes.cardcontent}>
              <Typography
                variant="h6"
                color="textSecondary"
                style={{ textAlign: "center" }}
                gutterBottom
              >
                Search Facility
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={() => fnGotoSearchFacility()}
                variant="contained"
                size="small"
                style={{ width: "100%" }}
                color="primary"
              >
                Map View
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item className={classes.card} xs={2}>
          <Card variant="outlined" className={classes.card}>
            <CardContent className={classes.cardcontent}>
              <Typography
                variant="h6"
                color="textSecondary"
                style={{ textAlign: "center" }}
                gutterBottom
              >
                Search Facility
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={() => fnGotoDataViewFacility()}
                variant="contained"
                size="small"
                style={{ width: "100%" }}
                color="primary"
              >
                Data View
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item className={classes.card} xs={2}>
          <Card variant="outlined" className={classes.card}>
            <CardContent className={classes.cardcontent}>
              <Typography
                variant="h6"
                color="textSecondary"
                style={{ textAlign: "center" }}
                gutterBottom
              >
                All Facilities Shapefile
              </Typography>
            </CardContent>
            {/* <CardActions>
							{facilitiesdata.length > 0 ? (
								<Button
									onClick={() => fnDownloadShapeFile()}
									variant="contained"
									size="small"
									style={{ width: '100%' }}
									color="primary"
								>
									Download
								</Button>
							) : (
								<Button
									onClick={() => fnGenerateFacilitiesFile()}
									variant="contained"
									size="small"
									style={{ width: '100%' }}
									color="primary"
								>
									Generate
								</Button>
							)}
						</CardActions> */}
          </Card>
        </Grid>

        <Grid item className={classes.card} xs={2}>
          <Card variant="outlined" className={classes.card}>
            <CardContent className={classes.cardcontent}>
              <Typography
                variant="h6"
                color="textSecondary"
                style={{ textAlign: "center" }}
                gutterBottom
              >
                Facilities GeoJSON
              </Typography>
            </CardContent>
            <CardActions>
              <DDGroupButtonComp
                fnDownloadGeoJSON={fnDownloadGeoJSON}
                param="geojson"
                title="Download GeoJSON"
              ></DDGroupButtonComp>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        maxWidth="sm"
        aria-labelledby="confirmation-dialog-title"
        open={slcdialog}
        onClose={fncloseslcdialog}
      >
        <DialogContent dividers>
          Search Facility by Pipeline Location Code
          <SearchPLCFacilityComp></SearchPLCFacilityComp>
        </DialogContent>
      </Dialog>
      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant="error"
          onClose={() => fnShowErrorMessage()}
          message="Error"
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(HomeComp);
