import * as actionType from "redux/actions/actionType";

let initialState = {
  GasStorage: 0,
  GasPlant: 0,
  Terminal: 0,
  Refinery: 0,
  Liquefaction: 0,
  CompressorStation: 0,
  PumpingStation: 0,
  Operators: 0,
  Pipeline: 0,
  Well: 0,
  IndustrialPlant: 0,
  PowerPlant: 0,
  HydrogenPlant: 0,
  CCUS: 0,
  CO2: 0,
  GatheringSystem:0
};

function facilitiescountReducer(state = initialState, action) {
  switch (action.type) {
    case actionType.FACILITIES_COUNT:
      state.GasStorage = action.payload.GasStorage;
      state.GasPlant = action.payload.GasPlant;
      state.Terminal = action.payload.Terminal;
      state.Refinery = action.payload.Refinery;
      state.Liquefaction = action.payload.Liquefaction;
      state.CompressorStation = action.payload.CompressorStation;
      state.PumpingStation = action.payload.PumpingStation;
      state.Operators = action.payload.Operators;
      state.Pipeline = action.payload.Pipeline;
      state.Well = action.payload.Well;
      state.IndustrialPlant = action.payload.IndustrialPlant;
      state.PowerPlant = action.payload.PowerPlant;
      state.HydrogenPlant = action.payload.HydrogenPlant;
      state.CCUS = action.payload.CCUS;
      state.CO2 = action.payload.CO2;
      state.GatheringSystem = action.payload.GatheringSystem;
      return state;

    default:
      return state;
  }
}

export default facilitiescountReducer;
