import React, { useEffect, useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import AlertNotificationComp from "components/custom/AlertNotificationComp";

import { customizedDateFormat } from "constants/utils";

import { plantstatus } from "lookuptables/global";

//date
import moment from "moment";

//linq
import linq from "linq";

//Redux imports
import { useSelector } from "react-redux";

//Api
import axios from "axios";

//Redux imports
import { useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import { withRouter } from "react-router-dom";

import { regulatorytypes, lengthmilestype } from "lookuptables/global";

const useStyles = makeStyles((theme) => ({
  operatordatacontainer: {
    margin: 10,
    width: "100%",
    "& .MuiPaper-root": {
      width: "100%",
    },
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      marginBottom: 3.5,
    },
  },

  operatorlist: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    maxHeight: 700,
    minHeight: 700,
  },
  operatordata: {
    padding: 10,
    height: 700,
    maxHeight: 700,
  },
  updatebutton: {
    width: "200px",
  },
}));

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

function GatheringSystemUpdateComp(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const operatorlookupvalues = useSelector(
    (state) => state.operatorsReducer.OperatorName
  );

  const citylookupvalues = useSelector(
    (state) => state.citynamesReducer.CityName
  );
  const countylookupvalues = useSelector(
    (state) => state.countynamesReducer.CountyName
  );
  const statelookupvalues = useSelector(
    (state) => state.statenamesReducer.StateName
  );

  const basinlookupvalues = useSelector(
    (state) => state.basinsReducer.BasinName
  );

  const gsdataRef = useRef(null);

  const [selectedindex, setSelectedIndex] = React.useState("");

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const [selectedowner, setSelectedOwner] = React.useState({});
  const [selectedoperator, setSelectedOperator] = React.useState({});
  const [selectedcity, setSelectedCity] = React.useState({});
  const [selectedcounty, setSelectedCounty] = React.useState({});
  const [selectedstate, setSelectedState] = React.useState({});
  const [selectedbasin, setSelectedBasin] = React.useState({});
  const [selectedStatus, setSelectedStatus] = React.useState({});

  const [statecounties, setStateCounties] = React.useState([]);

  const gsinitialstate = {
    GatheringSystem: "",
    OperatorName: null,
    OperatorId: null,
    Owner: null,
    OwnerId: null,
    P5Number: "",
    T4Permit: "",
    CountyCode: "",
    CityName: null,
    CityId: null,
    CountyName: null,
    CountyId: null,
    StateName: null,
    StateNameId: null,
    Basin: null,
    BasinId: null,

    DateRetired: null,
    DesignClass: "",
    PressureUnits: "",
    PipeSurfaceAreaUnits: "",
    PipeVolumeUnits: "",
    WallThickness: "",
    TSP_FERC_CID: "",
    key: 0,
  };

  const [gsvalues, setGSValues] = React.useState({});

  const [gsdata, setGSData] = React.useState([]);

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "info" });
  };

  const fnHandleChange = (prop) => (event) => {
    setGSValues({ ...gsvalues, [prop]: event.target.value });
  };

  const fnOnChange = (newvalue, type, typeid) => {
    if (newvalue !== null && newvalue !== undefined) {
      if (type === "Owner" || type === "Operator") {
        setGSValues({
          ...gsvalues,
          [type]: newvalue["OperatorName"],
          [typeid]: newvalue["OperatorId"],
        });
      } else {
        setGSValues({
          ...gsvalues,
          [type]: newvalue[type],
          [typeid]: newvalue[typeid],
        });
      }

      switch (type) {
        case "Operator":
          setSelectedOperator(newvalue);
          break;
        case "Owner":
          setSelectedOwner(newvalue);
          break;

        case "CityName":
          setSelectedCity(newvalue);
          break;
        case "CountyName":
          setSelectedCounty(newvalue);
          break;
        case "StateName":
          setSelectedState(newvalue);
          setSelectedCounty({});
          var filtercounties = linq
            .from(countylookupvalues)
            .where((a) => a.StateNameId == newvalue.StateNameId)
            .toArray();
          setStateCounties(filtercounties);
          break;

        case "Operator":
          setSelectedOperator(newvalue);
          break;
        case "Owner":
          setSelectedOwner(newvalue);
          break;
        case "BasinName":
          setSelectedBasin(newvalue);
          break;
      }
    }
  };

  const fnLoadGSData = (id) => {
    // setSelectedIndex(index);

    fnResetStateValues();

    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      fetchtype: "gatheringsystem",
      fetchid: id,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchsinglerecord", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while fetching record",
              variant: "error",
            });
          } else if (response.data[0].status == "no record found") {
            setStatusBase({
              show: true,
              message: "Operator data not found",
              variant: "info",
            });
          } else if (response.data[0].data !== undefined) {
            var resultobj = JSON.parse(JSON.stringify(gsinitialstate));

            Object.keys(response.data[0].data).forEach((element) => {
              if (
                response.data[0].data[element] !== null &&
                response.data[0].data[element] !== undefined
              ) {
                if (element === "OperatorName") {
                  var selectedvalue = linq
                    .from(operatorlookupvalues)
                    .where(
                      (a) => a.OperatorName == response.data[0].data[element]
                    )
                    .toArray();

                  setSelectedOperator({
                    OperatorName: selectedvalue[0].OperatorName,
                    OperatorId: selectedvalue[0].OperatorId,
                  });

                  resultobj.OperatorId = selectedvalue[0].OperatorId;
                }

                if (element === "Owner") {
                  var selectedvalue = linq
                    .from(operatorlookupvalues)
                    .where(
                      (a) => a.OperatorName == response.data[0].data[element]
                    )
                    .toArray();

                  setSelectedOwner({
                    OperatorName: selectedvalue[0].OperatorName,
                    OperatorId: selectedvalue[0].OperatorId,
                  });

                  resultobj.OwnerId = selectedvalue[0].OperatorId;
                }

                if (element === "CityName") {
                  var selectedvalue = linq
                    .from(citylookupvalues)
                    .where((a) => a.CityName == response.data[0].data[element])
                    .toArray();

                  setSelectedCity({
                    CityName: selectedvalue[0].CityName,
                    CityId: selectedvalue[0].CityId,
                  });

                  resultobj.CityId = selectedvalue[0].CityId;
                }

                if (element === "CountyName") {
                  var selectedvalue = linq
                    .from(countylookupvalues)
                    .where(
                      (a) => a.CountyName == response.data[0].data[element]
                    )
                    .toArray();

                  setSelectedCounty({
                    CountyName: selectedvalue[0].CountyName,
                    CountyId: selectedvalue[0].CountyId,
                  });

                  resultobj.CountyId = selectedvalue[0].CountyId;
                }

                if (element === "StateName") {
                  var selectedvalue = linq
                    .from(statelookupvalues)
                    .where((a) => a.StateName == response.data[0].data[element])
                    .toArray();

                  setSelectedState({
                    StateName: selectedvalue[0].StateName,
                    StateNameId: selectedvalue[0].StateNameId,
                  });

                  var filtercounties = linq
                    .from(countylookupvalues)
                    .where((a) => a.StateNameId == selectedvalue[0].StateNameId)
                    .toArray();
                  setStateCounties(filtercounties);

                  resultobj.StateNameId = selectedvalue[0].StateNameId;
                }

                if (element === "BasinId") {
                  var selectedvalue = linq
                    .from(basinlookupvalues)
                    .where((a) => a.BasinId == response.data[0].data[element])
                    .toArray();

                  setSelectedBasin({
                    BasinName: selectedvalue[0].BasinName,
                    BasinId: selectedvalue[0].BasinId,
                  });

                  resultobj.BasinId = selectedvalue[0].BasinId;
                }

                resultobj[element] = response.data[0].data[element];
              }
            });

            setGSValues(resultobj);
          }
        } else {
          setStatusBase({
            show: true,
            message:
              "Error while fetching Gathering System header data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching Gathering System data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnResetStateValues = () => {
    // let cloneobj = JSON.parse(JSON.stringify(operatorinitialstate));

    // setOperatorValues(cloneobj);

    setSelectedCity({});
    setSelectedCounty({});
    setSelectedState({});
    setSelectedBasin({});
    setSelectedOperator({});
    setSelectedOwner({});
  };

  const fnUpdateGSData = () => {
    var gskeys = [
      "CityId",
      "CountyId",
      "StateNameId",
      "BasinId",
      "OperatorId",
      "OwnerId",
    ];

    var updatevalues = [];
    gskeys.forEach((element) => {
      if (
        gsvalues[element] !== undefined &&
        gsvalues[element] !== null &&
        gsvalues[element] !== ""
      ) {
        if (typeof gsvalues[element] === "string") {
          updatevalues.push(element + " = '" + gsvalues[element] + "'");
        } else {
          updatevalues.push(element + " = " + gsvalues[element]);
        }
      } else {
        updatevalues.push(element + " = null");
      }
    });

    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      tablename: "GatheringSystem",
      query: updatevalues.join(","),
      key: gsvalues.key,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/updateheaderdata", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while updating",
              variant: "error",
            });
          } else if (response.data[0].status === "success") {
            setStatusBase({
              show: true,
              message: "Data updated successfully",
              variant: "info",
            });
          }
        } else {
          setStatusBase({
            show: true,
            message: "Error while updating gathering system data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while updating Gathering System data",
            variant: "error",
          });
        }
      }
    );
  };

  useEffect(() => {
    if (props.id > 0) {
      fnLoadGSData(props.id);
    }
  }, [props.id]);

  return (
    <>
      <Grid className={classes.operatordatacontainer} container>
        <Typography variant="title">Gathering System Data</Typography>
        {/* {JSON.stringify(gasplantvalues)} */}
        {gsvalues.key > 0 ? (
          <Grid
            container
            spacing="3"
            direction="column"
            className={classes.formcontainer}
          >
            <Grid item>
              <Grid
                container
                spacing="3"
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs="3">
                  <TextField
                    label="GatheringSystem"
                    id="GatheringSystemId"
                    value={gsvalues.GatheringSystem}
                    onChange={fnHandleChange("GatheringSystem")}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // error={pipelinevalues.PipelineName === ''}
                    disabled
                  />
                </Grid>
                <Grid item xs="3">
                  <Autocomplete
                    size="small"
                    options={operatorlookupvalues}
                    getOptionLabel={(option) =>
                      option.OperatorName === undefined
                        ? ""
                        : option.OperatorName
                    }
                    value={selectedoperator}
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        fnOnChange(newValue, "Operator", "OperatorId");
                      } else {
                        setSelectedOperator({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select Operator"}
                        placeholder="Operator"
                        error={selectedoperator.OperatorName === undefined}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs="3">
                  <Autocomplete
                    size="small"
                    options={operatorlookupvalues}
                    getOptionLabel={(option) =>
                      option.OperatorName === undefined
                        ? ""
                        : option.OperatorName
                    }
                    value={selectedowner}
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        fnOnChange(newValue, "Owner", "OwnerId");
                      } else {
                        setSelectedOwner({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select Owner"}
                        placeholder="Owner"
                        error={selectedowner.OperatorName === undefined}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs="3">
                  {/* {JSON.stringify(operatorvalues.City)} */}

                  <Autocomplete
                    size="small"
                    options={citylookupvalues}
                    getOptionLabel={(option) =>
                      option.CityName === undefined ? "" : option.CityName
                    }
                    value={selectedcity}
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        fnOnChange(newValue, "CityName", "CityId");
                      } else {
                        setSelectedCity({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select City"}
                        placeholder="City"
                        error={selectedcity.CityName === undefined}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing="3"
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs="3">
                  <Autocomplete
                    size="small"
                    options={statelookupvalues}
                    getOptionLabel={(option) =>
                      option.StateName === undefined ? "" : option.StateName
                    }
                    value={selectedstate}
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        fnOnChange(newValue, "StateName", "StateNameId");
                      } else {
                        setSelectedState({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select State"}
                        placeholder="State"
                        error={selectedstate.StateName === undefined}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs="3">
                  <Autocomplete
                    size="small"
                    options={statecounties}
                    getOptionLabel={(option) =>
                      option.CountyName === undefined ? "" : option.CountyName
                    }
                    value={selectedcounty}
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        fnOnChange(newValue, "CountyName", "CountyId");
                      } else {
                        setSelectedCounty({});
                      }
                    }}
                    disabled={statecounties.length === 0}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select County"}
                        placeholder="County"
                        error={selectedcounty.CountyName === undefined}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs="3">
                  <Autocomplete
                    size="small"
                    options={basinlookupvalues}
                    getOptionLabel={(option) =>
                      option.BasinName === undefined ? "" : option.BasinName
                    }
                    value={selectedbasin}
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        fnOnChange(newValue, "BasinName", "BasinId");
                      } else {
                        setSelectedBasin({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select Basin"}
                        placeholder="Basin"
                        error={selectedbasin.BasinName === undefined}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing="3"
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs="3">
                  <TextField
                    label="P5Number"
                    id="P5NumberId"
                    value={gsvalues.P5Number}
                    disabled
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs="3">
                  <TextField
                    label="T4Permit"
                    id="T4PermitId"
                    value={gsvalues.T4Permit}
                    disabled
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="CountyCode"
                    id="CountyCodeId"
                    value={gsvalues.CountyCode}
                    disabled
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    className={classes.updatebutton}
                    onClick={() => fnUpdateGSData()}
                  >
                    Update Gathering System
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>

      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(GatheringSystemUpdateComp);
